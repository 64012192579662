













































import MNotificationVue from "@/mixins/MNotification.vue";
import Vue from "vue";
export default Vue.extend({
  name: "ModalChooseConsumeProduct",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    propProductCode: {
      type: String,
      default: undefined,
    },
    propDefault: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      form: {
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        productId: undefined as string | undefined,
      }
    };
  },
  methods: {
    clearForm(): void {
      this.form = {
        productCode: undefined,
        productName: undefined,
        productId: undefined,
      };
    },
    save(): void {
      this.$emit("on-save", { data: this.form });
      this.clearForm();
      this.$emit("input", false);
    },
    cancel(): void {
      this.$emit("input", false);
    },
    onSelectProductCode({ meta }): void {
      this.form.productId = meta.meta.id;
      this.form.productName = meta.meta.name;
    },
    onSelectProductName({ meta }): void {
      this.form.productId = meta.meta.id;
      this.form.productCode = meta.meta.code;
    },
  }
});
