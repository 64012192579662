var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        title: _vm.$t("lbl_consumed_product"),
        width: 550,
        closable: false,
        "destroy-on-close": true
      }
    },
    [
      _c(
        "a-form-model",
        { attrs: { model: _vm.form } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_product_code") } },
            [
              _c("CSelectMasterProductCode", {
                attrs: {
                  "prop-search-by": "code~" + _vm.propProductCode + "*"
                },
                on: { "on-select": _vm.onSelectProductCode },
                model: {
                  value: _vm.form.productCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productCode", $$v)
                  },
                  expression: "form.productCode"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_product_name") } },
            [
              _c("CSelectMasterProductName", {
                attrs: {
                  "prop-search-by": "code~" + _vm.propProductCode + "*"
                },
                on: { "on-select": _vm.onSelectProductName },
                model: {
                  value: _vm.form.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productName", $$v)
                  },
                  expression: "form.productName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.cancel } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
          ]),
          _c(
            "a-button",
            {
              key: "submit",
              attrs: { type: "primary", icon: "save" },
              on: { click: _vm.save }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }